/* eslint-disable @typescript-eslint/camelcase */
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  EditUserRequestInterface,
  AddUserRequestInterface
} from "../contracts/UserRequest";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class EditUserApiRequest implements EditUserRequestInterface {
  private account_id: number;
  private status: string;
  private email: string;

  constructor(account_id: number, status: string, email: string) {
    this.account_id = account_id;
    this.status = status;
    this.email = email;
  }

  public toJSON() {
    const account_id = this.account_id;
    const status = this.status;
    const email = this.email;
    const payload = {
      account_id,
      status
    };
    if (email) {
      Object.assign(payload, {
        email
      });
    }
    return JSON.stringify(payload);
  }
}

export class AddUserApiRequest implements AddUserRequestInterface {
  private username: string;
  private email: string;
  private phone_number: string;
  private account_type: string;
  private role_id: number;
  private reference_id: number;

  constructor(
    username: string,
    email: string,
    phone_number: string,
    account_type: string,
    role_id: number,
    reference_id: number
  ) {
    this.username = username;
    this.email = email;
    this.phone_number = phone_number;
    this.account_type = account_type;
    this.role_id = role_id;
    this.reference_id = reference_id;
  }

  public toJSON() {
    const username = this.username;
    const email = this.email;
    const phone_number = this.phone_number;
    const account_type = this.account_type;
    const role_id = this.role_id;
    const reference_id = this.reference_id;

    return JSON.stringify({
      username,
      email,
      phone_number,
      account_type,
      role_id,
      reference_id
    });
  }
}

export class RequestListUser {
  [x: string]: any;
  page = 1;
  limit = 10;
  account_type = "";
  reference = "";
  status = "";
  isTotalData = false;
  search = "";
  version = "1";

  constructor(fields?: Partial<RequestListUser>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class EditRoleApiRequest implements EditUserRequestInterface {
  accountId = 0;
  roleId = 0;
  status = "";
  constructor(fields?: Partial<EditRoleApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}
